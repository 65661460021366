<template>
  <section>
    <h2 class="titulo-links-escolha">CADASTRAR UNIDADE DE ENSINO</h2>
    <erro-alerta :erro="erro" :msg_erro="msg_erro" />

    <pm-Card>
      <template v-slot:content>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-4">
            <label>CNPJ da Escola</label>
            <!-- <pm-InputText  v-model="escola.cnpj"  v-on:blur="VerificarCNPJ" v-mask="{mask: 'NN.NNN.NNN/NNNN-NN' }"  /> -->
            <pm-InputMask
              v-model="escola.cnpj"
              v-on:blur="VerificarCNPJ"
              mask="99.999.999/9999-99"
            />
          </div>

          <div class="field col-12 md:col-6">
            <label>Nome da Escola</label>
            <pm-InputText
              v-model="escola.nome"
              v-on:keypress="isLetter($event)"
            />
          </div>

          <div class="field col-12 md:col-2">
            <label>Código INEP</label>
            <pm-InputText
              v-on:keypress="isNumber($event)"
              v-model="escola.codigo_INEP"
            />
          </div>

          <div class="field col-12 md:col-8">
            <label>Email</label>
            <pm-InputText v-model="escola.email" />
          </div>
          <div class="field col-12 md:col-4">
            <label>Telefone</label>
            <pm-InputText
              v-model="escola.telefone"
              v-mask="{ mask: '(NN) NNNN-NNNN', model: 'escola.telefone' }"
            />
          </div>

          <div class="field col-12 md:col-1">
            <label>CEP</label>
            <pm-InputText
              max="8"
              v-model="escola.cep"
              v-on:keypress="isNumber($event)"
              v-mask="{ mask: 'NNNNN-NNN', model: 'escola.cep' }"
              v-on:input="buscarCep()"
            />
          </div>

          <div class="field col-12 md:col-2">
            <label>Logradouro</label>
            <pm-InputText
              :disabled="cepValido == 0 || cepValido == 2"
              v-model="escola.logradouro"
            />
          </div>

          <div class="field col-12 md:col-1">
            <label>Número</label>
            <pm-InputText
              :disabled="cepValido == 0 || cepValido == 2"
              v-model="escola.numero"
            />
          </div>

          <div class="field col-12 md:col-3">
            <label>Complemento</label>
            <pm-InputText
              :disabled="cepValido == 0 || cepValido == 2"
              v-model="escola.complemento"
            />
          </div>

          <div class="field col-12 md:col-2">
            <label>Bairro/Distrito</label>
            <pm-InputText
              :disabled="cepValido == 0 || cepValido == 2"
              v-model="escola.bairro"
            />
          </div>

          <div class="field col-12 md:col-2">
            <label>Cidade</label>
            <pm-InputText
              :disabled="cepValido == 0 || cepValido == 2"
              v-model="escola.cidade"
            />
          </div>

          <div class="field col-12 md:col-1">
            <label>Estado</label>
            <pm-InputText
              :disabled="cepValido == 0 || cepValido == 2"
              v-model="escola.estado"
            />
          </div>

          <!--<div class="field col-12 md:col-2">
            <pm-Button label="Voltar"  class="p-button-danger" icon="pi pi-angle-left" iconPos="left" @click="voltar()" />
          </div>

          <div class="field col-12 md:col-2" >
            <pm-Button label="Cadastrar"
             v-if="escola.cnpj && escola.nome && escola.codigo_INEP && escola.email && escola.telefone && escola.cep && escola.estado && escola.cidade && escola.bairro && escola.numero && escola.logradouro"
             @click="cadastrar"   />
          </div>-->
        </div>
        <div class="grid grid-nogutter justify-content-between">
          <pm-Button
            label="Voltar"
            class="p-button-danger"
            icon="pi pi-angle-left"
            iconPos="left"
            @click="voltar()"
          />

          <pm-Button
            class="p-button-success"
            label="Cadastrar"
            icon="pi pi-check"
            iconPos="right"
            v-if="escola.nome"
            @click="cadastrar"
          />
        </div>
      </template>
    </pm-Card>

    <!--<div class="form-elements">
      <div class="row">
        <div class="flex xs12">
          <va-card :title="$t('forms.inputs.title')">
            <va-card-content>
              <form>
                <div class="row">
                  <div class="flex md4 sm6 xs12">
                    <va-input
                      type="text"
                      v-model="escola.cnpj"
                      :label="$t('CNPJ')"
                      v-mask="{mask: 'NN.NNN.NNN/NNNN-NN', model: 'escola.cnpj' }"
                    />
                  </div>
                  <div class="flex md6 sm6 xs12">
                    <va-input
                      v-model="escola.nome"
                      :label="$t('Nome')"
                      v-on:keypress="isLetter($event)"
                    />
                  </div>
                  <div class="flex md2 sm6 xs12">
                    <va-input
                      maxlength="8"
                      v-on:keypress="isNumber($event)"
                      v-model="escola.codigo_INEP"
                      :label="$t('Código INEP')"
                    />
                  </div>
                  <div class="flex md8 sm6 xs12">
                    <va-input
                      v-model="escola.email"
                      :label="$t('Email')"
                    />
                  </div>
                  <div class="flex md4 sm6 xs12">
                    <va-input
                      v-model="escola.telefone"
                      :label="$t('Telefone')"
                       v-mask="{mask: '(NN) NNNN-NNNN', model: 'escola.telefone' }"
                    />
                  </div>
                  <div class="flex md2 sm6 xs12">
                    <va-input
                      v-on:keypress="isNumber($event)"
                      type="text"
                      max="8"
                      v-model="escola.cep"
                      :label="$t('CEP')"
                      v-mask="{mask: 'NNNNN-NNN', model: 'escola.cep' }"
                      v-on:input="buscarCep()"
                    />
                  </div>

                  <div class="flex md5 sm6 xs12">
                    <va-input
                      :disabled="cepValido == 0 || cepValido == 2"
                      v-model="escola.logradouro"
                      :label="$t('Logradouro')"
                    />
                  </div>

                  <div class="flex md2 sm6 xs12">
                    <va-input
                      :disabled="cepValido == 0 || cepValido == 2"
                      v-model="escola.numero"
                      :label="$t('Número')"
                    />
                  </div>

                  <div class="flex md3 sm6 xs12">
                    <va-input
                      :disabled="cepValido == 0 || cepValido == 2"
                      v-model="escola.complemento"
                      :label="$t('Complemento')"
                    />
                  </div>

                  <div class="flex md3 sm6 xs12">
                    <va-input
                      :disabled="cepValido == 0 || cepValido == 2"
                      v-model="escola.bairro"
                      :label="$t('Bairro ou Distrito')"
                    />
                  </div>

                   <div class="flex md3 sm6 xs12">
                    <va-input
                      :disabled="cepValido == 0 || cepValido == 2"
                      v-model="escola.cidade"
                      :label="$t('Cidade')"
                    />
                  </div>

                  <div class="flex md2 sm6 xs12">
                    <va-input
                      :disabled="cepValido == 0 || cepValido == 2"
                      v-model="escola.estado"
                      :label="$t('Estado')"
                    />
                  </div>

                  <div class="col-md-12" style="margin-top:10px">
                    <va-button class="mr-2 mb-2"  v-if="escola.cnpj && escola.nome && escola.codigo_INEP && escola.email && escola.telefone && escola.cep && escola.estado && escola.cidade && escola.bairro && escola.numero && escola.logradouro" @click="cadastrar"> {{ $t('Cadastrar') }}</va-button>
                    <va-button class="mr-2 mb-2" color="danger" @click="this.$router.push({name: 'escolas'});">Voltar</va-button>
                  </div>
                </div>
              </form>
            </va-card-content>
          </va-card>
        </div>
      </div>
    </div>-->
  </section>
</template>

<script>
import erroAlerta from "@/components/erro_alerta.vue";
import { buscarCEP } from "@/class/buscarCEP";
import { Escola } from "@/class/escolas";
import { Verificar } from "@/class/verificar";
import vueMask from "vue-jquery-mask";
import store_token_info from "@/store/store_token_info.js";

export default {
  components: {
    erroAlerta,
  },
  name: "form-elements",
  data() {
    return {
      cnpj: null,
      escola: {
        id: null,
        cnpj: null,
        nome: null,
        codigo_INEP: null,
        email: null,
        cep: "",
        email: null,
        numero: null,
        complemento: null,
        bairro: null,
        cidade: null,
        estado: null,
        logradouro: "",
        telefone: null,
        logradouro: null,
        user_id: store_token_info.usuario_logado,
      },
      cepValido: 2,
      isLoading: false,
      errorMessages: ["Digite um CEP válido!"],
      erro: 0,
      msg_erro: "",
      cnpjValido: "",
    };
  },
  methods: {
    voltar() {
      this.$router.push({ name: "escolas" });
    },
    clear(field) {
      this[field] = "";
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ.()-- ]+$/.test(char))
        return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0123456789]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    async VerificarCNPJ() {
      let data = await Verificar.verificarCNPJ(this.escola.cnpj);
      console.log("aqwe", data);
    },
    async buscarCep() {
      this.escola.cep = this.escola.cep.replace(" ", "");
      var cepTemp = this.escola.cep.replace("-", "").replace(" ", "");
      if (cepTemp.length == 8) {
        let data = await buscarCEP.searchCep(cepTemp);
        if (data["erro"]) {
          this.$vaToast.init({
            message: "CEP inválido!",
            iconClass: "fa-star-o",
            position: "button-left",
            color: "danger",
            duration: 2500,
            fullWidth: false,
          });
        } else {
          (this.escola.logradouro = data["logradouro"]),
            (this.escola.estado = data["uf"]),
            (this.escola.cidade = data["localidade"]),
            (this.escola.bairro = data["bairro"]),
            (this.cepValido = 1);
        }
      }
    },
    async cadastrar() {
      try {
        const data = await Escola.cadastrar(this.escola);
        this.$vaToast.init({
          message: "Cadastrado com sucesso!",
          iconClass: "fa-star-o",
          position: "top-right",
          color: "success",
          duration: 2500,
          fullWidth: false,
        });
        this.$router.push({ name: "escolas" });
      } catch (e) {
        this.erro = 1;
        if (e.response.data.validacao) {
          //this.msg_erro = e.response.data.validacao;
          let a = [];
          a = e.response.data.validacao;

          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value[0],
                iconClass: "fa-star-o",
                position: "top-right",
                duration: 3500,
                fullWidth: false,
                color: "danger",
              });
            }, 500);
          });
        } else {
          //this.msg_erro = e.response.data;
          let a = [];
          a = e.erro;
          setTimeout(() => {
            this.$vaToast.init({
              message: a,
              iconClass: "fa-star-o",
              position: "top-right",
              duration: 3500,
              fullWidth: false,
              color: "danger",
            });
          }, 500);
        }
      }
      //this.$router.push({name: 'escolas'});
    },
  },
};
</script>

<style>
.row.row-inside {
  max-width: none;
}

.form-control:focus {
  background-color: #f6f7f6;
  border-color: #673ab7;
  box-shadow: none;
}
.form-control {
  background-color: #f6f7f6;
  border: 1px solid #f6f6f6;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.va-input-wrapper,
fieldset {
  margin-bottom: 0.5rem;
}
</style>
